import type { GetStaticProps, NextPage } from "next";
import {
  ABOUT_CONTENT,
  ABOUT_HEADLINE,
  BENEFITS_QUOTE,
  BENEIFTS_HEADLINE,
  CONTACT_BUTTON_TEXT,
  CONTACT_DESC,
  CONTACT_EMAIL,
  CONTACT_ERROR,
  CONTACT_HEADLINE,
  CONTACT_MESSAGE,
  CONTACT_SUCCESS,
  FOOTER_CV_ERROR,
  SKILLS_HEADLINE,
  SKILLS_SUBLINE,
  TESTIMONIALS_HEADLINE,
  TESTIMONIALS_SUBLINE,
} from "src/constants";
import Home from "src/templates/Home";
import {
  HEADER_BUTTON_TEXT,
  HEADER_HEADLINE,
  HEADER_SUBLINE,
} from "src/constants";
import Seo from "src/components/reusable/headless/Seo/index";
import { getAllTestimonials } from "src/api/queries";
import { Testimonial } from "src/api/types";
import JSONLd from "src/components/reusable/headless/JSONLd/index";

interface Props {
  reviews: Testimonial[];
}

const Index: NextPage<Props> = ({ reviews = [] }) => {
  return (
    <div>
      <Seo />
      <JSONLd reviews={reviews} />
      <Home
        header={{
          content: {
            headline: HEADER_HEADLINE,
            subline: HEADER_SUBLINE,
            buttonText: HEADER_BUTTON_TEXT,
          },
        }}
        about={{
          content: {
            headline: ABOUT_HEADLINE,
            content: ABOUT_CONTENT,
          },
        }}
        skills={{
          headline: SKILLS_HEADLINE,
          subline: SKILLS_SUBLINE,
        }}
        benefits={{
          headline: BENEIFTS_HEADLINE,
          subline: BENEFITS_QUOTE,
        }}
        testimonials={{
          headline: TESTIMONIALS_HEADLINE,
          subline: TESTIMONIALS_SUBLINE,
        }}
        contact={{
          headline: CONTACT_HEADLINE,
          subline: CONTACT_DESC,
          inputPlaceholder: CONTACT_EMAIL,
          textareaPlaceholder: CONTACT_MESSAGE,
          buttonText: CONTACT_BUTTON_TEXT,
          successMessage: CONTACT_SUCCESS,
          errorMessage: CONTACT_ERROR,
        }}
        footer={{
          cvErrorMessage: FOOTER_CV_ERROR,
        }}
      />
    </div>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  //fetch reviews
  const reviewsRes = await getAllTestimonials();

  return {
    props: {
      reviews: reviewsRes.ok ? reviewsRes.data : [],
    },
    revalidate: 60 * 60 * 24, // Revalidate once per day
  };
};

export default Index;
