import React from "react";
import Illustration from "src/components/reusable/molecules/Illustration";
import { m } from "framer-motion";

export interface Props {
  inView: boolean;
}

const AboutIllustration: React.FC<Props> = ({ inView }) => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : {}}
      transition={{
        delay: 0.5,
      }}
    >
      <Illustration
        animation="thinking"
        options={{
          loop: true,
          autoplay: true,
        }}
        height={300}
        width="fit-content"
      />
    </m.div>
  );
};

export default AboutIllustration;
