import { Box } from "@chakra-ui/react";
import React from "react";
import Waves, {
  Props as WavesProps,
} from "src/components/reusable/molecules/Waves";

export type Props = WavesProps;

const HeaderWaves: React.FC<Props> = ({ ...props }) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      transform="rotateZ(-30deg) translateY(-18%) translateX(5%)"
      width="250%"
      height="1680"
      zIndex={-1}
      pointerEvents="none"
      maxW="900px"
    >
      <Waves {...props} />
    </Box>
  );
};

export default HeaderWaves;
