import { Box } from "@chakra-ui/react";
import React from "react";
import Headline, { Props as HeadlineProps } from "./Headline";
import Subline, { Props as SublineProps } from "./Subline";
import Button, { Props as ButtonProps } from "./Button";

export type Props = HeadlineProps & SublineProps & ButtonProps;

const Content: React.FC<Props> = ({
  headline,
  subline,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Box textAlign={{ base: "center", lg: "start" }}>
      <Headline headline={headline} />
      <Subline subline={subline} />
      <Button buttonText={buttonText} onButtonClick={onButtonClick} />
    </Box>
  );
};

export default Content;
