import React from "react";
import { Box } from "@chakra-ui/react";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import { useInView } from "react-intersection-observer";
import SectionHeadline, {
  Props as SectionHeadlineProps,
} from "src/components/reusable/atoms/SectionHeadline";
import SectionSubline, {
  Props as SectionSublineProps,
} from "src/components/reusable/atoms/SectionSubline";
import dynamic from "next/dynamic";

const Container = dynamic(() => import("./Container"), { ssr: false });

export type Props = Omit<SectionHeadlineProps, "inView"> &
  Omit<SectionSublineProps, "inView">;

const Skills: React.FC<Props> = ({ headline, subline }) => {
  //Section inview
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

  return (
    <LazyMotion>
      <Box ref={ref}>
        <Box textAlign="center">
          <SectionHeadline inView={inView} headline={headline} />
          <SectionSubline subline={subline} inView={inView} />
        </Box>
        <Container inView={inView} />
      </Box>
    </LazyMotion>
  );
};

export default Skills;
