import React, { useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { requireDOMReady } from "src/helpers/utils";
import type { Props } from "./types";

const Illustration: React.FC<Props> = ({
  animation,
  options,
  style,
  isClickToPauseDisabled = true,
  priority = "low",
  ...props
}) => {
  //Lazy loaded lottie player
  const [animationData, setAnimationData] = useState<object>();

  //Lazy load lottie animation data
  const lazyLoadAnimationData = useCallback(() => {
    import(`/public/lotties/${animation}.json`).then(setAnimationData);
  }, [animation]);

  useEffect(() => {
    if (priority === "high")
      lazyLoadAnimationData(); //Instance load for high prio
    else return requireDOMReady(lazyLoadAnimationData); //Lazy load for low prio
  }, [lazyLoadAnimationData, priority]);

  return animationData ? (
    <Lottie
      {...props}
      options={{
        ...options,
        animationData,
      }}
      isClickToPauseDisabled={isClickToPauseDisabled}
      style={{
        cursor: isClickToPauseDisabled ? "default" : "pointer",
        ...style,
      }}
    />
  ) : (
    <></>
  );
};

export * from "./types";
export default Illustration;
