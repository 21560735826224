import { ChakraProps } from "@chakra-ui/react";

const useTruncationStyles = () => {
  return {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "pre",
  } as ChakraProps;
};

export default useTruncationStyles;
