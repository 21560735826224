import React from "react";
import { Props } from "./types";
import {
  AiOutlineStar as OutlineStar,
  AiFillStar as FillStar,
} from "react-icons/ai";
import { Box } from "@chakra-ui/react";

const Rate: React.FC<Props> = ({ value, outOf = 5 }) => {
  return (
    <Box display="flex" alignItems="center">
      {Array(value)
        .fill(0)
        .map((_, index) => (
          <FillStar key={index} color="gold" />
        ))}
      {Array(outOf - value)
        .fill(0)
        .map((_, index) => (
          <OutlineStar key={index} color="silver" />
        ))}
    </Box>
  );
};

export * from "./types";
export default Rate;
