import React from "react";
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";

const Button: React.FC<ChakraButtonProps> = ({ ...props }) => {
  return <ChakraButton {...props} />;
};

export default Button;
