import React from "react";
import { m } from "framer-motion";
import { Props } from "./types";
import Heading from "../Heading";
import LazyMotion from "../../headless/LazyMotion";

const SectionSubline: React.FC<Props> = ({
  subline,
  inView,
  motionDelay = 0,
  ...props
}) => {
  return (
    <LazyMotion>
      <m.div
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{
          duration: 0.5,
          stiffness: 900,
          damping: 600,
          repeat: 0,
          delay: 0.2 + motionDelay,
        }}
      >
        <Heading
          as="h4"
          fontSize={{ base: "md", lg: "lg" }}
          color="gray.400"
          fontWeight={400}
          mt={1}
          {...props}
        >
          {subline}
        </Heading>
      </m.div>
    </LazyMotion>
  );
};

export * from "./types";
export default SectionSubline;
