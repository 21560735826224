import { Box } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import Heading from "src/components/reusable/atoms/Heading";
import Text from "src/components/reusable/atoms/Text";
import { useColors } from "src/helpers/hooks";

export interface BeneiftShape {
  title: string;
  Icon: IconType;
  desc: string;
}

const Beneift: React.FC<BeneiftShape> = ({ title, Icon, desc }) => {
  //colors
  const [primary200] = useColors(["primary.200"]);

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      gap={4}
      py={12}
      px={6}
      shadow="0 0 15px silver"
      height="100%"
      bg="white"
      rounded="md"
    >
      <Icon color={primary200} size={60} />
      <Box>
        <Heading as="h5" fontSize="lg">
          {title}
        </Heading>
        <Text color="gray.400" mt={2}>
          {desc}
        </Text>
      </Box>
    </Box>
  );
};

export default Beneift;
