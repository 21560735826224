import { Box } from "@chakra-ui/react";
import React, { useCallback } from "react";
import {
  BsCloudArrowDownFill as DownloadIcon,
  BsFillArrowUpCircleFill as RideIcon,
  BsFillBookmarkCheckFill as ProjectsIcon,
} from "react-icons/bs";
import Button from "src/components/reusable/atoms/Button";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import { m } from "framer-motion";
import FullWidth from "src/components/reusable/organisms/FullWidth";
import { usePrimaryCv } from "src/api/hooks";
import { useProjects, useToast } from "src/helpers/hooks";

export interface Props {
  cvErrorMessage: string;
}

const Footer: React.FC<Props> = ({ cvErrorMessage }) => {
  //Primary CV
  const { data } = usePrimaryCv();

  //Feedback toast
  const { fireErrorToast } = useToast();

  //Projects
  const { show: showProjects } = useProjects();

  /* Bubbles click handlers */

  const goTopTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const downloadCv = useCallback(() => {
    if (!data?.ok)
      return fireErrorToast(cvErrorMessage, { position: "top-left" }); //Failed
    //Open CV in a new tab so that it could be previewed before downloading
    window.open(data.data.file, "_blank");
  }, [data, fireErrorToast, cvErrorMessage]);

  //Buttons
  const buttons = [
    {
      Icon: DownloadIcon,
      text: "Download CV",
      onClick: downloadCv,
    },
    {
      Icon: ProjectsIcon,
      text: "View Projects",
      onClick: showProjects,
    },
    {
      Icon: RideIcon,
      text: "Free Ride",
      onClick: goTopTop,
    },
  ];

  return (
    <LazyMotion>
      <m.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ amount: 0.9, once: true }}
      >
        <FullWidth>
          <Box
            as="footer"
            display="flex"
            flexDir={{ base: "column", md: "row" }}
            alignItems="center"
            justifyContent={{ md: "center" }}
            gap={{ base: 8, md: 16 }}
            mt={32}
            py={8}
            px={4}
            bg="gray.50"
          >
            {buttons.map(({ Icon, text, onClick }, index) => (
              <Button
                key={index}
                cursor="pointer"
                colorScheme="primary"
                rightIcon={<Icon color="white" />}
                width="100%"
                maxW="200px"
                onClick={onClick}
              >
                {text}
              </Button>
            ))}
          </Box>
        </FullWidth>
      </m.div>
    </LazyMotion>
  );
};

export default Footer;
