import React from "react";
import { m } from "framer-motion";
import { useColors } from "src/helpers/hooks";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import type { Props } from "./types";

const Waves: React.FC<Props> = ({
  animated = true,
  minColor = "primary.100",
  maxColor = "primary.300",
}) => {
  //Gradient colors
  const [minGradient, maxGradient] = useColors([minColor, maxColor]);

  //SVG waves
  const waves = [
    {
      dFrom:
        "M 0 0 L 0 612.409 Q 129.4 533.989 258.8 488 T 517.6 438.18 T 776.4 410.041 T 1035.2 285.285 T 1294 326.287 L 1294 0 Z",
      dTo: "M 0 0 L 0 624.779 Q 129.4 538.376 258.8 505.673 T 517.6 521.543 T 776.4 424.584 T 1035.2 333.488 T 1294 299.111 L 1294 0 Z",
      transition: {
        duration: 3,
        delay: 2,
      },
    },
    {
      dFrom:
        "M 0 0 L 0 604.053 Q 129.4 581.45 258.8 542.666 T 517.6 503.574 T 776.4 437.788 T 1035.2 372.536 T 1294 266.247 L 1294 0 Z",
      dTo: "M 0 0 L 0 613.129 Q 129.4 590.684 258.8 559.102 T 517.6 520.089 T 776.4 397.684 T 1035.2 332.145 T 1294 264.892 L 1294 0 Z",
      transition: {
        duration: 5,
        delay: 1,
      },
    },
    {
      dFrom:
        "M 0 0 L 0 624.779 Q 129.4 538.376 258.8 505.673 T 517.6 521.543 T 776.4 424.584 T 1035.2 333.488 T 1294 299.111 L 1294 0 Z",
      dTo: "M 0 0 L 0 604.053 Q 129.4 581.45 258.8 542.666 T 517.6 503.574 T 776.4 437.788 T 1035.2 372.536 T 1294 266.247 L 1294 0 Z",
      transition: {
        duration: 2,
        delay: -2,
      },
    },
    {
      dFrom:
        "M 0 0 L 0 607.252 Q 129.4 554.444 258.8 537.638 T 517.6 501.998 T 776.4 472.914 T 1035.2 334.463 T 1294 293.072 L 1294 0 Z",
      dTo: "M 0 0 L 0 613.129 Q 129.4 590.684 258.8 559.102 T 517.6 520.089 T 776.4 397.684 T 1035.2 332.145 T 1294 264.892 L 1294 0 Z",
      transition: {
        duration: 7,
        delay: -4,
      },
    },
    {
      dFrom:
        "M 0 0 L 0 613.129 Q 129.4 590.684 258.8 559.102 T 517.6 520.089 T 776.4 397.684 T 1035.2 332.145 T 1294 264.892 L 1294 0 Z",
      dTo: "M 0 0 L 0 607.252 Q 129.4 554.444 258.8 537.638 T 517.6 501.998 T 776.4 472.914 T 1035.2 334.463 T 1294 293.072 L 1294 0 Z",
      transition: {
        duration: 2,
        delay: -3,
      },
    },
  ];

  return (
    <svg width="100%" height="100%" viewBox="0 0 1294 680">
      <g transform="translate(647,-334) scale(1,1) translate(-647,-334)">
        <defs>
          <linearGradient id="waves-grad" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0" stopColor={minGradient} stopOpacity={0.2} />
            <stop offset="1" stopColor={maxGradient} stopOpacity={0.5} />
          </linearGradient>
        </defs>
        <LazyMotion>
          {waves.map(({ dFrom, dTo, transition }, index) => (
            <m.path
              key={index}
              fill="url(#waves-grad)"
              d={dFrom}
              animate={animated ? { d: dTo } : {}}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                ...transition,
              }}
            />
          ))}
        </LazyMotion>
      </g>
    </svg>
  );
};

export * from "./types";
export default Waves;
