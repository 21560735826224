import { useCallback, useEffect, useRef } from "react";

const useScrollEnd = (cb: CallableFunction, threshold = 10) => {
  //Ref for scrollable DOM element
  const ref = useRef<HTMLElement>(null);

  //Tracks scroll progress
  const trackProgress = useCallback(() => {
    if (!ref.current) return; //No DOM element supplied
    //Detect scroll end
    const scrolledSoFar = ref.current.scrollTop + ref.current.clientHeight;
    const maxScroll = ref.current.scrollHeight;
    if (scrolledSoFar + threshold >= maxScroll) cb(); //Execute given callback when meeting criteria
  }, [threshold, cb]);

  useEffect(() => {
    const element = ref.current; //DOM Element

    //Setup listener
    element?.addEventListener("scroll", trackProgress);

    return () => {
      //Cleanup
      element?.removeEventListener("scroll", trackProgress);
    };
  }, [trackProgress]);

  //Return DOM element reference
  return { ref };
};

export default useScrollEnd;
