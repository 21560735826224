import { useCallback } from "react";
import toast from "react-hot-toast";

const useToast = () => {
  //Toasts default duration
  const duration = 3000;

  //Success
  const fireSuccessToast = useCallback(
    (message: string, opts: Parameters<typeof toast.success>[1] = {}) => {
      toast.success(message, { duration, ...opts });
    },
    []
  );

  //Error
  const fireErrorToast = useCallback(
    (message: string, opts: Parameters<typeof toast.error>[1] = {}) => {
      toast.error(message, { duration, ...opts });
    },
    []
  );

  //Promise
  const firePromiseToast = useCallback(
    (
      promise: Promise<unknown>,
      params: Parameters<typeof toast.promise>[1],
      opts: Parameters<typeof toast.promise>[2] = {}
    ) => {
      toast.promise(promise, params, { duration, ...opts });
    },
    []
  );

  return { fireSuccessToast, fireErrorToast, firePromiseToast };
};

export default useToast;
