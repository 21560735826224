import { Box } from "@chakra-ui/react";
import React from "react";
import type { Testimonial as TestimonialShape } from "src/api/types";
import Text from "src/components/reusable/atoms/Text";
import Rate from "src/components/reusable/molecules/Rate";
import {
  ImQuotesRight as QuotesRight,
  ImQuotesLeft as QuotesLeft,
} from "react-icons/im";
import { useColors, useTruncationStyles } from "src/helpers/hooks";

const Testimonial: React.FC<TestimonialShape> = ({ name, rate, content }) => {
  //Colors
  const [primary400] = useColors(["primary.400"]);

  //Truncate text
  const turncationStyles = useTruncationStyles();

  return (
    <Box rounded="sm" shadow="lg" px={6} py={12} bg="white" pos="relative">
      <Box pos="absolute" top={0} left={0} transform="translate(30%, -50%)">
        <QuotesLeft color={primary400} size={35} />
      </Box>
      <Box pos="absolute" bottom={0} right={0} transform="translate(-30%, 50%)">
        <QuotesRight color={primary400} size={35} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={4}
        gap={4}
      >
        <Text
          as="span"
          maxW="50%"
          fontSize="2xl"
          fontWeight={600}
          {...turncationStyles}
        >
          {name}
        </Text>
        <Rate value={rate} />
      </Box>
      <Text color="gray.400" fontStyle="italic" as="q">
        {content}
      </Text>
    </Box>
  );
};

export default Testimonial;
