import React from "react";
import Heading from "src/components/reusable/atoms/Heading";
import { m } from "framer-motion";

export interface Props {
  headline: string;
}

const Headline: React.FC<Props> = ({ headline }) => {
  return (
    <m.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.5,
        stiffness: 900,
        damping: 600,
        repeat: 0,
      }}
    >
      <Heading as="h1" fontSize={{ base: "4xl", lg: "5xl" }}>
        {headline}
      </Heading>
    </m.div>
  );
};

export default Headline;
