import { Box } from "@chakra-ui/react";
import React from "react";
import Headline, { Props as HeadlineProps } from "./Headline";
import Text, { Props as TextProps } from "./Text";

export type Props = HeadlineProps & TextProps;

const Content: React.FC<Props> = ({ headline, content, inView }) => {
  return (
    <Box maxW={{ lg: "60%" }}>
      <Headline headline={headline} inView={inView} />
      <Text content={content} inView={inView} />
    </Box>
  );
};

export default Content;
