import React from "react";
import { m } from "framer-motion";
import Text from "src/components/reusable/atoms/Text";

export interface Props {
  content: string;
  inView: boolean;
}

const ContentText: React.FC<Props> = ({ content, inView }) => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : {}}
      transition={{
        duration: 0.5,
        stiffness: 900,
        damping: 600,
        repeat: 0,
        delay: 0.3,
      }}
    >
      <Text mt={2} textAlign="justify" color="gray.500">
        {content}
      </Text>
    </m.div>
  );
};

export default ContentText;
