import { portfolioItemsKeys } from "src/api/constants/keys";
import {
  ApiResponse,
  Paginated,
  PortfolioItem,
  SuccessResponse,
} from "src/api/types";
import useSWRInfinite from "swr/infinite";

const usePortfolioItems = () => {
  //Data
  const data = useSWRInfinite<ApiResponse<Paginated<PortfolioItem>>>(
    (page, previousPageData: SuccessResponse<Paginated<PortfolioItem>>) => {
      if (
        previousPageData &&
        previousPageData.data.current >= previousPageData.data.total
      )
        return null; //Reached the end
      return `${portfolioItemsKeys.ALL}?page=${page + 1}`; //Next key
    }
  );

  return {
    ...data,
  };
};

export default usePortfolioItems;
