import { cvKeys } from "src/api/constants/keys";
import { ApiResponse, Cv } from "src/api/types";
import useSWR from "swr";

const usePrimaryCv = () => {
  //Data
  const data = useSWR<ApiResponse<Cv>>(cvKeys.PRIMARY);

  return {
    ...data,
  };
};

export default usePrimaryCv;
