import { Box } from "@chakra-ui/react";
import React from "react";
import { useInView } from "react-intersection-observer";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import Container from "./Container";
import Content, { Props as ContentProps } from "./Content";

export type Props = Omit<ContentProps, "inView">;

const Testimonials: React.FC<Props> = ({ headline, subline }) => {
  //Section inview
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true });

  return (
    <LazyMotion>
      <Box ref={ref}>
        <Content headline={headline} subline={subline} inView={inView} />
        <Container inView={inView} />
      </Box>
    </LazyMotion>
  );
};

export default Testimonials;
