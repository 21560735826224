import React from "react";
import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from "@chakra-ui/react";

const Text: React.FC<ChakraTextProps> = ({ ...props }) => {
  return <ChakraText {...props} />;
};

export default Text;
