import { Box } from "@chakra-ui/react";
import React, { useRef } from "react";
import Header, { Props as HeaderProps } from "src/components/ui/Header";
import About, { Props as AboutProps } from "src/components/ui/About";
import Benefits, { Props as BenefitsProps } from "src/components/ui/Benefits";
import Skills, { Props as SkillsProps } from "src/components/ui/Skills";
import Testimonials, {
  Props as TestimonialsProps,
} from "src/components/ui/Testimonials";
import Contact, { Props as ContactProps } from "src/components/ui/Contact";
import Footer, { Props as FooterProps } from "src/components/ui/Footer";

export interface Props {
  header: HeaderProps;
  about: AboutProps;
  skills: SkillsProps;
  benefits: BenefitsProps;
  testimonials: TestimonialsProps;
  contact: ContactProps;
  footer: FooterProps;
}

const Home: React.FC<Props> = ({
  header,
  about,
  skills,
  benefits,
  testimonials,
  contact,
  footer,
}) => {
  //About section ref
  const ref = useRef<HTMLDivElement>(null);

  //Header button click
  const onButtonClick = () =>
    ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });

  return (
    <Box>
      <Header {...header} content={{ ...header.content, onButtonClick }} />
      <Box display="flex" flexDir="column" alignItems="stretch" gap={32}>
        <About {...about} ref={ref} />
        <Skills {...skills} />
        <Benefits {...benefits} />
        <Testimonials {...testimonials} />
        <Contact {...contact} />
      </Box>
      <Footer {...footer} />
    </Box>
  );
};

export default Home;
