import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import {
  IoSpeedometer as SpeedIcon,
  IoLogoReact as AccuracyIcon,
  IoCodeSlash as CodeIcon,
  IoHelpCircle as HelpIcon,
} from "react-icons/io5";
import {
  FaMoneyBillWave as MoneyIcon,
  FaComments as CommentsIcon,
} from "react-icons/fa";
import Beneift from "./Benefit";
import { m } from "framer-motion";

export interface Props {
  inView: boolean;
}

const Container: React.FC<Props> = ({ inView }) => {
  //benefits
  const benefits = [
    {
      Icon: SpeedIcon,
      title: "Fast Delivery",
      desc: "Your time is valuable I understand. That's why I always give my best shot to get what is required done for you within the least time possible.",
    },
    {
      Icon: MoneyIcon,
      title: "Low Cost",
      desc: "Money is for business. You won't need to put much money to get started with your project. Instead you will have them saved for investement in that project.",
    },
    {
      Icon: AccuracyIcon,
      title: "High Accuracy",
      desc: "No body likes bugs or unexpected issues. That's where accuracy matters. You will have an optimized and flawlessly functioning application!",
    },
    {
      Icon: CodeIcon,
      title: "Clean Code",
      desc: "It might be the case that there will be some people who will get their hands dirty with the code again. That's why I always make sure that the code is well written and human readable.",
    },
    {
      Icon: CommentsIcon,
      title: "Clear Communication",
      desc: "Communication has the largest impact on the progress. Thus, I always make sure it is clear and straight forward and you will be always provided with the updates along the progress!",
    },
    {
      Icon: HelpIcon,
      title: "Free Support",
      desc: "Even after the project is completed. You're always welcomed to come back and ask for help if you've got any issues and definitely without paying any extra charges!",
    },
  ];

  //active benefit index
  const [activeIndex, setActiveIndex] = useState(0);

  //next beneift index
  const [nextActiveIndex, setNextAciveIndex] = useState(1);

  //last index
  const lastIndex = benefits.length - 1;

  //Total flips
  const [flips, setFlips] = useState(0);

  //Track flips
  const flipsCount = useRef(0);

  //least time to wait before flipping in milliseconds
  const waitTimeout = 300;

  //Wait timeout has elapsed
  const [timeoutElapsed, setTimeoutElapsed] = useState(true);

  //Increment active index
  const incrementActiveIndex = useCallback(() => {
    //Increment active index after every 2 flips
    setTimeout(() => {
      if (flipsCount.current % 2 != 0) {
        setActiveIndex((activeIndex) =>
          activeIndex + 2 > lastIndex ? 0 : activeIndex + 2
        );
        setTimeoutElapsed(true);
      } else {
        setNextAciveIndex((nextActiveIndex) =>
          nextActiveIndex + 2 > lastIndex ? 1 : nextActiveIndex + 2
        );
        setTimeoutElapsed(true);
      }
    }, waitTimeout);
    setTimeoutElapsed(false);
  }, [lastIndex]);

  //Flip card and increment active index
  const flip = useCallback(() => {
    if (!timeoutElapsed) return; //Needs to wait
    //Make a flip
    setFlips((flips) => flips + 1);
    flipsCount.current += 1;
    //Increment index
    incrementActiveIndex();
  }, [incrementActiveIndex, timeoutElapsed]);

  //Running interval
  const interval = useRef<NodeJS.Timer>(
    null
  ) as React.MutableRefObject<NodeJS.Timer>;

  //Stop the interval
  const stopInterval = useCallback(() => {
    if (interval.current) clearInterval(interval.current);
  }, []);

  //Start the interval
  const startInterval = useCallback(() => {
    //Clear any running interval
    if (interval.current) stopInterval();
    //Do a flip every 10s
    interval.current = setInterval(flip, 10000);
  }, [flip, stopInterval]);

  //Interval starts upon mounting
  useEffect(() => {
    startInterval();
    return () => stopInterval();
  }, [startInterval, stopInterval]);

  //Paused interval when out of focus
  useEffect(() => {
    window.addEventListener("focus", startInterval);
    window.addEventListener("blur", stopInterval);

    return () => {
      window.removeEventListener("focus", startInterval);
      window.removeEventListener("blur", stopInterval);
    };
  }, [startInterval, stopInterval]);

  //active beneift
  const activeBenefit = benefits[activeIndex];

  //next active beneift
  const nextActiveBenefit = benefits[nextActiveIndex];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : {}}
      transition={{
        duration: 0.6,
        stiffness: 1200,
        damping: 400,
        delay: 0.4,
      }}
      style={{ width: "100%", height: "100%", maxWidth: "300px" }}
    >
      <Box
        width="100%"
        height={{ base: 500, sm: 400 }}
        textAlign="center"
        style={{
          perspective: "1000px",
        }}
        cursor="pointer"
      >
        <Box
          pos="relative"
          transition={`transform ${waitTimeout}ms ease-in-out`}
          transform={`rotateY(${180 * flips}deg)`}
          style={{
            transformStyle: "preserve-3d",
          }}
          onClick={flip}
          height="100%"
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            transform="rotateY(180deg)"
            style={{
              backfaceVisibility: "hidden",
            }}
          >
            <Beneift {...nextActiveBenefit} />
          </Box>
          <Box
            position="absolute"
            width="100%"
            height="100%"
            style={{
              backfaceVisibility: "hidden",
            }}
          >
            <Beneift {...activeBenefit} />
          </Box>
        </Box>
      </Box>
    </m.div>
  );
};

export default Container;
