import { Box } from "@chakra-ui/react";
import React from "react";
import SectionHeadline, {
  Props as SectionHeadlineProps,
} from "src/components/reusable/atoms/SectionHeadline";
import SectionSubline, {
  Props as SectionSublineProps,
} from "src/components/reusable/atoms/SectionSubline";

export type Props = SectionHeadlineProps & SectionSublineProps;

const Content: React.FC<Props> = ({ headline, subline, inView }) => {
  return (
    <Box
      textAlign={{ base: "center", lg: "start" }}
      maxW={{ lg: "50%" }}
      color="white"
      zIndex={1}
    >
      <SectionHeadline
        headline={headline}
        inView={inView}
        fontSize={{ base: "3xl", lg: "5xl" }}
      />
      <SectionSubline
        subline={subline}
        fontStyle="italic"
        inView={inView}
        mt={4}
        color="white"
      />
    </Box>
  );
};

export default Content;
