import React from "react";
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";

const Input: React.FC<ChakraInputProps> = ({ ...props }) => {
  return <ChakraInput {...props} />;
};

export default Input;
