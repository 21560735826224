import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import Input from "src/components/reusable/atoms/Input";
import Textarea from "src/components/reusable/atoms/Textarea";
import Button from "src/components/reusable/atoms/Button";
import { m } from "framer-motion";
import { useMail } from "src/api/hooks";
import { useToast } from "src/helpers/hooks";

export interface Props {
  inputPlaceholder: string;
  textareaPlaceholder: string;
  buttonText: string;
  successMessage: string;
  errorMessage: string;
  inView: boolean;
}

const Form: React.FC<Props> = ({
  inputPlaceholder,
  textareaPlaceholder,
  buttonText,
  successMessage,
  errorMessage,
  inView,
}) => {
  //Mail
  const { mailAdmin } = useMail();

  //Feedback toasts
  const { fireSuccessToast, fireErrorToast } = useToast();

  //Form data
  const [data, setData] = useState({ email: "", message: "" });

  //Progress indicator
  const [progress, setProgress] = useState(false);

  //Input change handler
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setData({ ...data, [e.target.name]: e.target.value });
    },
    [data]
  );

  //Data validation
  const isValid = useMemo(() => {
    //Email and message just need to be there
    return !!data.email && !!data.message;
  }, [data]);

  //Form submission handle
  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      //Validate data
      if (!isValid) return;
      //Start progress
      setProgress(true);
      //Send email
      const response = await mailAdmin(data.email, data.message);
      //Show feedback
      if (response.ok) fireSuccessToast(successMessage);
      else fireErrorToast(errorMessage);
      //End progress
      setProgress(false);
    },
    [
      data,
      mailAdmin,
      fireSuccessToast,
      fireErrorToast,
      successMessage,
      errorMessage,
      isValid,
    ]
  );

  return (
    <m.div
      initial={{ opacity: 0, x: 150 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{
        duration: 0.5,
        stiffness: 1200,
        ease: "easeOut",
        delay: 0.5,
      }}
      style={{ width: "100%", maxWidth: "500px" }}
    >
      <Box
        as="form"
        display="flex"
        flexDir="column"
        alignItems="stretch"
        gap={2}
        onSubmit={onSubmit}
        w="full"
      >
        <Input
          name="email"
          type="email"
          placeholder={inputPlaceholder}
          value={data.email}
          onChange={handleChange}
          isRequired
          aria-label="email"
        />
        <Textarea
          name="message"
          placeholder={textareaPlaceholder}
          value={data.message}
          onChange={handleChange}
          resize="none"
          height={150}
          isRequired
          aria-label="message"
        />
        <Box textAlign={{ base: "center", lg: "start" }} mt={1}>
          <Button
            colorScheme="primary"
            paddingInline={8}
            type="submit"
            isLoading={progress}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </m.div>
  );
};

export default Form;
