import { Box } from "@chakra-ui/react";
import React from "react";
import { useInView } from "react-intersection-observer";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import Content, { Props as ContentProps } from "./Content";
import Form, { Props as FormProps } from "./Form";

export type Props = Omit<ContentProps, "inView"> & Omit<FormProps, "inView">;

const Contact: React.FC<Props> = ({
  headline,
  subline,
  inputPlaceholder,
  textareaPlaceholder,
  successMessage,
  errorMessage,
  buttonText,
}) => {
  //Section inview
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

  return (
    <LazyMotion>
      <Box
        ref={ref}
        display="flex"
        flexDir={{ base: "column", lg: "row" }}
        alignItems={{ base: "center", lg: "flex-start" }}
        justifyContent={{ lg: "space-between" }}
        gap={{ base: 6, lg: 12 }}
      >
        <Content headline={headline} subline={subline} inView={inView} />
        <Form
          inputPlaceholder={inputPlaceholder}
          textareaPlaceholder={textareaPlaceholder}
          buttonText={buttonText}
          successMessage={successMessage}
          errorMessage={errorMessage}
          inView={inView}
        />
      </Box>
    </LazyMotion>
  );
};

export default Contact;
