import React from "react";
import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from "@chakra-ui/react";

const Textarea: React.FC<ChakraTextareaProps> = ({ ...props }) => {
  return <ChakraTextarea {...props} />;
};

export default Textarea;
