import { useCallback } from "react";
import Client from "src/api/client";
import { mailKeys } from "src/api/constants/keys";

const useMail = () => {
  //Send an email
  const mailAdmin = useCallback((email: string, message: string) => {
    const response = Client.post({
      path: mailKeys.ADMIN,
      body: { email, message },
    });
    return response;
  }, []);

  return {
    mailAdmin,
  };
};

export default useMail;
