import React from "react";
import { Box } from "@chakra-ui/react";

const Preloading = () => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      w="full"
      h="full"
      zIndex={0}
      bgColor="primary.200"
    />
  );
};

export default Preloading;
