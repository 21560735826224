import { Box } from "@chakra-ui/react";
import React from "react";
import SectionHeadline, {
  Props as SectionHeadlineProps,
} from "src/components/reusable/atoms/SectionHeadline";
import SectionSubline, {
  Props as SectionSublineProps,
} from "src/components/reusable/atoms/SectionSubline";

export type Props = SectionHeadlineProps & SectionSublineProps;

const Content: React.FC<Props> = ({ headline, subline, inView }) => {
  return (
    <Box textAlign="center">
      <SectionHeadline headline={headline} inView={inView} />
      <SectionSubline subline={subline} inView={inView} />
    </Box>
  );
};

export default Content;
