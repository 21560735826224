import { Box } from "@chakra-ui/react";
import React from "react";
import { m } from "framer-motion";
import { useTopTestimonials } from "src/api/hooks";
import Testimonial from "./Testimonial";
import FullWidth from "src/components/reusable/organisms/FullWidth";

export interface Props {
  inView: boolean;
}

const Container: React.FC<Props> = ({ inView }) => {
  const { data } = useTopTestimonials();

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : {}}
      transition={{
        delay: 0.5,
      }}
    >
      <FullWidth>
        <Box
          bg="gray.50"
          mt={4}
          py={{ base: 16, lg: 20 }}
          px={{ base: 8, md: 16 }}
          display="flex"
          flexDir={{ base: "column", lg: "row" }}
          alignItems="center"
          justifyContent={{ lg: "center" }}
          gap={{ base: 8, lg: "3%" }}
        >
          {data?.ok &&
            data.data.map((testimonial, index) => (
              <Box
                key={testimonial._id}
                transform={{ lg: index === 1 ? "scale(1.2, 1.1)" : "none" }}
                zIndex={{ lg: index === 1 ? 1 : 0 }}
                width={{ base: "100%", lg: "500px" }}
                maxW="500px"
              >
                <Testimonial {...testimonial} />
              </Box>
            ))}
        </Box>
      </FullWidth>
    </m.div>
  );
};

export default Container;
