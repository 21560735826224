import React from "react";
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from "@chakra-ui/react";

const Heading: React.FC<ChakraHeadingProps> = ({ ...props }) => {
  return <ChakraHeading {...props} />;
};

export default Heading;
