import React from "react";
import { Box } from "@chakra-ui/react";
import { m } from "framer-motion";
import Illustration from "src/components/reusable/molecules/Illustration";

const HeaderIllustration = () => {
  return (
    <Box display={{ base: "none", lg: "block" }}>
      <m.div
        initial={{ y: -5 }}
        animate={{ y: 5 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 1.5,
          when: "afterChildren",
        }}
      >
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.6,
          }}
        >
          <Illustration
            animation="coding"
            priority="high"
            options={{
              loop: true,
              autoplay: true,
            }}
            height={500}
            width="fit-content"
          />
        </m.div>
      </m.div>
    </Box>
  );
};

export default HeaderIllustration;
