import { Box } from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { useInView } from "react-intersection-observer";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import Content, { Props as ContentProps } from "./Content";
import Illustration from "./Illustration";

export interface Props {
  content: Omit<ContentProps, "inView">;
}

const About = ({ content }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  //Section inview
  const { ref: intersectionRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <LazyMotion>
      <Box ref={intersectionRef}>
        <Box
          ref={ref}
          display="flex"
          flexDir={{ base: "column", lg: "row" }}
          alignItems="center"
          justifyContent={{ lg: "space-between" }}
          py={8}
          px={{ base: 8, md: 16 }}
          bg="gray.50"
          boxShadow="lg"
          rounded="md"
        >
          <Content {...content} inView={inView} />
          <Illustration inView={inView} />
        </Box>
      </Box>
    </LazyMotion>
  );
};

export default forwardRef(About);
