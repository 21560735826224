import { testimonialKeys } from "src/api/constants/keys";
import { ApiResponse, Testimonial } from "src/api/types";
import useSWR from "swr";

const useTopTestimonials = () => {
  //Data
  const data = useSWR<ApiResponse<Testimonial[]>>(testimonialKeys.TOP);

  return {
    ...data,
  };
};

export default useTopTestimonials;
