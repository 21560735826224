import { NextSeo } from "next-seo";
import React from "react";
import {
  DEPLOYMENT_URL,
  SEO_AUTHOR,
  SEO_DESCRIPTION,
  SEO_KEYWORDS,
  SEO_TITLE,
} from "src/constants";

const Seo = () => {
  return (
    <NextSeo
      title={SEO_TITLE}
      description={SEO_DESCRIPTION}
      twitter={{
        cardType: "app",
        site: SEO_TITLE,
        handle: SEO_AUTHOR,
      }}
      additionalMetaTags={[
        {
          name: "keywords",
          content: SEO_KEYWORDS,
        },
        {
          name: "author",
          content: SEO_AUTHOR,
        },
      ]}
      openGraph={{
        type: "website",
        locale: "en_US",
        title: SEO_TITLE,
        description: SEO_DESCRIPTION,
        site_name: SEO_TITLE,
        url: DEPLOYMENT_URL,
        images: [
          {
            url: `${DEPLOYMENT_URL}/og-01.png`,
            width: 762,
            height: 552,
            alt: "Og Image Alt",
          },
        ],
      }}
    />
  );
};

export default Seo;
