import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import Waves, { Props as WavesProps } from "./Waves";
import Content, { Props as ContentProps } from "./Content";
import Illustration from "./Illustration";
import { useMedia } from "src/helpers/hooks";

export interface Props {
  content: ContentProps;
  waves?: WavesProps;
}

const Header: React.FC<Props> = ({ content, waves }) => {
  const { isDesktopOrLarger } = useMedia();

  const [shouldDisplayIllustration, setShouldDisplayIllustration] =
    useState(false);

  useEffect(() => {
    setShouldDisplayIllustration(isDesktopOrLarger);
  }, [isDesktopOrLarger]);

  return (
    <>
      <Waves {...waves} />
      <Box
        minHeight="100vh"
        display="flex"
        flexDir={{ base: "column", lg: "row" }}
        justifyContent={{ base: "center", lg: "space-between" }}
        alignItems="center"
      >
        <LazyMotion>
          <Content {...content} />
          {shouldDisplayIllustration && <Illustration />}
        </LazyMotion>
      </Box>
    </>
  );
};

export default Header;
