import React from "react";
import { WebPageJsonLd } from "next-seo";
import { DEPLOYMENT_URL, SEO_AUTHOR, SEO_DESCRIPTION } from "src/constants";
import type { Props } from "./types";
import ReviewJSONLd from "../custom/ReviewJSONLd";

const JSONLd: React.FC<Props> = ({ reviews }) => {
  return (
    <>
      {reviews.map((review) => (
        <ReviewJSONLd
          key={`review-jsonld-${review._id}-index`}
          itemReviewed={{
            type: "SoftwareApplication",
            name: `${review.name}'s Project`,
            applicationCategory: "WebApplication",
            operatingSystem: "Native",
            aggregateRating: {
              ratingCount: 1,
              ratingValue: review.rate.toString(),
            },
            offers: {
              "@type": "Offer",
              lowPrice: "$10/hr",
              highPrice: "$15/hr",
              price: "12.00",
              priceCurrency: "USD/hr",
            },
          }}
          name={review.name}
          rating={{ ratingValue: review.rate.toString() }}
          reviewBody={review.content}
          recordedAt={review.createdAt}
        />
      ))}
      <WebPageJsonLd
        key="webpage-jsonld-index"
        description={SEO_DESCRIPTION}
        id={DEPLOYMENT_URL}
        lastReviewed="2022-05-04T15:03:29.741Z"
        reviewedBy={{
          type: "Person",
          name: SEO_AUTHOR,
        }}
      />
    </>
  );
};

export default JSONLd;
