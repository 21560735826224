import Head from "next/head";
import React from "react";
import type { Props } from "./types";

const ReviewJSONLd: React.FC<Props> = ({
  name,
  reviewBody,
  recordedAt,
  rating,
  itemReviewed,
}) => {
  //Schema
  const schema = React.useMemo(
    () => ({
      "@context": "https://schema.org/",
      "@type": "Review",
      itemReviewed: {
        "@type": itemReviewed.type,
        ...itemReviewed,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: rating.ratingValue,
        worstRating: rating.worstRating || 1,
        bestRating: rating.bestRating || 5,
      },
      author: {
        "@type": "Person",
        name: name,
      },
      reviewBody: reviewBody,
      datePublished: recordedAt,
    }),
    [recordedAt, name, reviewBody, rating, itemReviewed]
  );

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Head>
  );
};

export default ReviewJSONLd;
