import { useMediaQuery } from "@chakra-ui/react";
import { breakpoints } from "src/constants";

const useMedia = () => {
  const [
    isLargerThanXs,
    isLargerThanSm,
    isLargerThanMd,
    isLargerThanLg,
    isLargerThanXl,
  ] = useMediaQuery([
    `(min-width: ${breakpoints.xs}px)`,
    `(min-width: ${breakpoints.sm}px)`,
    `(min-width: ${breakpoints.md}px)`,
    `(min-width: ${breakpoints.lg}px)`,
    `(min-width: ${breakpoints.xl}px)`,
  ]);

  return {
    isNarrowScreen: !isLargerThanXs,
    isSmallMobile: isLargerThanXs && !isLargerThanSm,
    isMobile: isLargerThanSm && !isLargerThanMd,
    isTablet: isLargerThanMd && !isLargerThanLg,
    isDesktop: isLargerThanLg && !isLargerThanXl,
    isLargeDesktop: isLargerThanXl,
    isSmallMobileOrLarger: isLargerThanXs,
    isMobileOrLarger: isLargerThanSm,
    isTabletOrLarger: isLargerThanMd,
    isDesktopOrLarger: isLargerThanLg,
    isLargeDestopOrLarger: isLargerThanXl,
  };
};

export default useMedia;
