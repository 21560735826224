import React from "react";
import Heading from "../Heading";
import { m } from "framer-motion";
import { Props } from "./types";
import LazyMotion from "../../headless/LazyMotion";

const SectionHeadline: React.FC<Props> = ({
  headline,
  inView,
  motionDelay = 0,
  ...props
}) => {
  return (
    <LazyMotion>
      <m.div
        initial={{ opacity: 0, y: 30 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{
          duration: 0.5,
          stiffness: 900,
          damping: 600,
          repeat: 0,
          delay: motionDelay,
        }}
      >
        <Heading as="h3" fontSize={{ base: "2xl", lg: "3xl" }} {...props}>
          {headline}
        </Heading>
      </m.div>
    </LazyMotion>
  );
};

export * from "./types";
export default SectionHeadline;
