import React from "react";
import Heading from "src/components/reusable/atoms/Heading";
import { m } from "framer-motion";

export interface Props {
  subline?: string;
}

const Subline: React.FC<Props> = ({ subline }) => {
  return (
    <m.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.3,
        stiffness: 400,
        damping: 600,
        repeat: 0,
        delay: 0.3,
      }}
    >
      <Heading
        as="h2"
        mt={2}
        fontSize={{ base: "md", lg: "lg" }}
        color="gray.500"
        fontWeight="normal"
        maxW="300px"
      >
        {subline}
      </Heading>
    </m.div>
  );
};

export default Subline;
