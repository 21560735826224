import React, { useCallback } from "react";
import {
  LazyFeatureBundle,
  LazyMotion as FramerLazyMotion,
} from "framer-motion";
import type { Props } from "./types";
import type { DefaultImport } from "src/types";

const LazyMotion: React.FC<Props> = ({
  children,
  enableAdvancedFeatures = false,
}) => {
  //Async loading for features
  const loadFeatures = useCallback(async () => {
    return (
      await (import(
        `./features/${enableAdvancedFeatures ? "advanced" : "basic"}`
      ) as DefaultImport)
    ).default;
  }, [enableAdvancedFeatures]);

  return (
    <FramerLazyMotion features={loadFeatures as LazyFeatureBundle}>
      {children}
    </FramerLazyMotion>
  );
};

export * from "./types";
export default LazyMotion;
