/* API Key Routes */

export const testimonialKeys = {
  ALL: "/testimonial",
  TOP: "/testimonial/top",
  ONE: "/testimonial/{id}",
};

export const mailKeys = {
  ADMIN: "/mail/admin",
};

export const cvKeys = {
  ALL: "/portfolio/cv",
  PRIMARY: "/portfolio/cv/primary",
};

export const portfolioItemsKeys = {
  ALL: "/portfolio/item",
  SEARCH: "/portfolio/item/search",
  ONE: "/portfolio/item/{id}",
};
