import React from "react";
import { m } from "framer-motion";
import Button from "src/components/reusable/atoms/Button";

export interface Props {
  buttonText?: string;
  onButtonClick?: () => void;
}

const HeaderButton: React.FC<Props> = ({ buttonText, onButtonClick }) => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.3,
        stiffness: 400,
        damping: 600,
        repeat: 0,
        delay: 0.5,
      }}
    >
      <Button colorScheme="primary" mt={5} onClick={onButtonClick}>
        {buttonText}
      </Button>
    </m.div>
  );
};

export default HeaderButton;
