import { Box } from "@chakra-ui/react";
import React from "react";
import { Props } from "./types";

const FullWidth: React.FC<Props> = ({ children }) => {
  return <Box marginInline={{ base: -8, lg: "-10vw" }}>{children}</Box>;
};

export * from "./types";
export default FullWidth;
