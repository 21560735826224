import React from "react";
import Heading from "src/components/reusable/atoms/Heading";
import { m } from "framer-motion";

export interface Props {
  inView: boolean;
  headline: string;
}

const Headline: React.FC<Props> = ({ headline, inView }) => {
  return (
    <m.div
      initial={{ opacity: 0, y: 30 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{
        duration: 0.5,
        stiffness: 900,
        damping: 600,
        repeat: 0,
      }}
    >
      <Heading
        as="h3"
        fontSize={{ base: "2xl", lg: "3xl" }}
        textAlign={{ base: "center", lg: "start" }}
        color="blackAlpha.700"
      >
        {headline}
      </Heading>
    </m.div>
  );
};

export default Headline;
