import React from "react";
import { Box } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import Content, { Props as ContentProps } from "./Content";
import Container from "./Container";
import { useColors } from "src/helpers/hooks";
import dynamic from "next/dynamic";
import Preloading from "./Preloading";

const Particles = dynamic(
  () => import("src/components/reusable/organisms/Particles"),
  { ssr: false, loading: () => <Preloading /> }
);

export type Props = Omit<ContentProps, "inView">;

const Benefits: React.FC<Props> = ({ headline, subline }) => {
  //Section inview
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

  //Color
  const [primary200] = useColors(["primary.200"]);

  return (
    <LazyMotion>
      <Box
        ref={ref}
        position="relative"
        py={16}
        px={{ base: 8, md: 16 }}
        boxShadow="2xl"
        rounded="xl"
        overflow="hidden"
      >
        <Particles name="v1" bgColor={primary200} />
        <Box
          display="flex"
          flexDir={{ base: "column", lg: "row-reverse" }}
          alignItems={{ base: "center", lg: "flex-start" }}
          justifyContent={{ lg: "space-around" }}
          gap={16}
          zIndex={1}
        >
          <Content headline={headline} subline={subline} inView={inView} />
          <Container inView={inView} />
        </Box>
      </Box>
    </LazyMotion>
  );
};

export default Benefits;
